import { gql, useQuery, useLazyQuery } from "@apollo/client";
import Fragments from "./fragments";

const useOffers = (restaurantId, where = {}) => {
  const QUERY = gql`
    query offersByRestaurant(
      $restaurantId: ID!
      # $sort: String!
      # $limit: Int!
      # $start: Int!
      $where: JSON
    ) {
      offersByRestaurant(
        id: $restaurantId
        # limit: $limit
        # start: $start
        # sort: $sort
        where: $where
      ) {
        ...OfferFragment
      }
    }
    ${Fragments.offer}
  `;
  const { data, loading, error, refetch } = useQuery(QUERY, {
    fetchPolicy: "network-only",
    variables: {
      restaurantId,
      where,
    },
  });

  return { data, loading, error, refetch };
};

export default useOffers;
