import { gql } from "@apollo/client";
import { useLazyQuery } from "@apollo/client";

const useRestaurantMedia = () => {
  const QUERY = gql`
    query RestaurantMedia(
      $id: ID!
      $limit: Int!
      $start: Int!
      $search: String
    ) {
      restaurnatMedia(
        id: $id
        limit: $limit
        start: $start
        sort: "created_at:desc"
        search: $search
      ) {
        count
        values {
          id
          url
          formats
          size
          name
          alternativeText
          created_at
          caption
        }
      }
    }
  `;
  const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(QUERY);

  const onExecuteQuery = (variables) => {
    executeQuery({
      variables,
    });
  };
  return [data, loading, error, onExecuteQuery, refetch];
};

export default useRestaurantMedia;
