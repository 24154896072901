import React, { useEffect, useMemo, useState } from "react";
import { useLocalStorage, useSessionStorage } from "../hooks";
import _ from "lodash";
import auth from "../utils/auth";

const UserAuthContext = React.createContext({});
const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [
    userLocalStorage,
    setUserLocalStorage,
    removeUserLocalStorage,
  ] = useLocalStorage("userLocalStorageInfoAndTokenPortal", {});
  const [
    userSessionStorage,
    setUserSessionStorage,
    removeUserSessionStorage,
  ] = useSessionStorage("userSessionStorageInfoAndTokenPortal", {});

  useEffect(() => {
    setLoading(true);
    if (!_.isEmpty(userLocalStorage)) {
      setUser(userLocalStorage);
      console.log("userLocalStorage", userLocalStorage);
    }
    if (!_.isEmpty(userSessionStorage)) {
      setUser(userSessionStorage);
      console.log("userSessionStorage", userSessionStorage);
    }
    setLoading(false);
  }, [userSessionStorage, userLocalStorage]);

  const onSetUser = (data) => {
    if (_.isEmpty(data)) return;

    setUser(data);
    if (data.rememberMe) setUserLocalStorage(data);
    else setUserSessionStorage(data);
  };
  const removeUser = () => {
    if (user.rememberMe) removeUserLocalStorage();
    else removeUserSessionStorage();

    setUser({});
  };

  const isAuthenticated = useMemo(() => {
    return user && user.accessToken ? true : false;
  }, [user]);

  return (
    <UserAuthContext.Provider
      value={{
        user,
        setUser: onSetUser,
        removeUser,
        isAuthenticated,
        isLoading: loading,
      }}
    >
      {children}
    </UserAuthContext.Provider>
  );
};

// use it without the context or hook

const getUserInfo = () => {
  const userSessionStorageInfoAndToken = auth.get(
    "userSessionStorageInfoAndTokenPortal"
  );
  const userLocalStorageInfoAndToken = auth.get(
    "userLocalStorageInfoAndTokenPortal"
  );

  if (!_.isEmpty(userSessionStorageInfoAndToken))
    return userSessionStorageInfoAndToken;
  if (!_.isEmpty(userLocalStorageInfoAndToken))
    return userLocalStorageInfoAndToken;
  return {};
};

export { UserContextProvider, UserAuthContext, getUserInfo };
