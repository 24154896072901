import { gql } from "@apollo/client";

const address = gql`
  fragment AddressFragment on ComponentLocationAddress {
    address_1
    address_2
    postalCode
    country
    city
    province
    firstName
    lastName
    phone
    description
  }
`;

const offer = gql`
  fragment OfferFragment on Offer {
    id
    code
    type
    discount
    archived
    expiresAt
  }
`;
const location = gql`
  fragment LocationFragment on Locations {
    name
    currency
    taxes {
      rate
      description
    }
    address {
      ...AddressFragment
    }
  }
  ${address}
`;

const lineitem = gql`
  fragment LineItemFragment on ComponentOrderLineitems {
    item {
      id
      price
      photo {
        url
      }
      description
      name
    }
    title
    unitPrice
    totalPrice
    note
    choices {
      title
      item {
        id
        name
        price
      }
      modifier {
        id
        name
      }
      unitPrice
      quantity
    }
    quantity
  }
`;

const transaction = gql`
  fragment transactionsFragment on Transactions {
    paid
    amountReceived
    riskScore
    riskLevel
    riskReason
    status
    lastCharge {
      charge
    }
  }
`;

const deliver = gql`
  fragment DeliverFragment on Delivers {
    id
    created_at
    updated_at
    token
    state
    trackURL
    pickedUpAt
    acceptedAt
    declinedAt
    declineReason
    driverNetwork {
      name
      support {
        firstname
        lastname
        phone
        email
      }
    }
  }
`;

const order = gql`
  fragment OrderFragment on Orders {
    id
    number
    displayOrderId
    created_at
    state
    approvedAt
    preparationTime
    cancelReason
    canceledAt
    total
    tip
    subtotal
    taxTotal
    discountTotal
    deliveryFees
    type
    archive
    email
    note
    phone
    scheduleTime {
      display
      from
      to
    }
    lineitems {
      ...LineItemFragment
    }
    shippingAddress {
      ...AddressFragment
    }
    billingAddress {
      ...AddressFragment
    }
    location {
      ...LocationFragment
    }
    transaction {
      ...transactionsFragment
    }
    deliver {
      ...DeliverFragment
    }
    offer {
      ...OfferFragment
    }
  }

  ${transaction}
  ${address}
  ${location}
  ${lineitem}
  ${deliver}
  ${offer}
`;

const restaurant = gql`
  fragment RestaurantFragment on Restaurants {
    id
    name
    settings {
      orders {
        prefixId
        suffixId
        startAt
      }
      features {
        sms
        enableDeliverNotify
      }
    }
  }
`;

const fragments = { address, order, location, lineitem, restaurant, offer };
export default fragments;
