import React, { useState, useContext, useEffect } from "react";
import { useUserMe } from "../queries";
import { UserAuthContext } from "./index";
import _ from "lodash";

const EmployeeContext = React.createContext({});

const EmployeeContextProvider = ({ children }) => {
  const [employee, setEmployee] = useState({});
  const { isAuthenticated } = useContext(UserAuthContext);
  const [userMe, loading, error, executeUserMe] = useUserMe();

  useEffect(() => {
    if (!isAuthenticated) return;
    executeUserMe();
  }, [isAuthenticated, executeUserMe]);

  useEffect(() => {
    if (_.isEmpty(userMe)) return;
    if (_.isEmpty(userMe.me)) return;

    setEmployee({
      ...userMe.me,
    });
  }, [userMe, loading, error]);

  return (
    <EmployeeContext.Provider
      value={{
        employee,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
};
export default EmployeeContext;
export { EmployeeContextProvider, EmployeeContext };
