import { useEffect, useState } from "react";
import firebase from "firebase/app";

// Firebase web config
const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
};

let instance = null;

const getFirebase = () => {
  if (typeof window !== "undefined") {
    if (instance) return instance;
    instance = firebase.initializeApp(config);
    return instance;
  }

  return null;
};

const useFirebase = () => {
  const [instance, setInstance] = useState(null);

  useEffect(() => {
    setInstance(getFirebase());
  }, []);

  return instance;
};
export default useFirebase;
