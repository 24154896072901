import React from "react";
import Alert from "@paljs/ui/Alert";

const mapType = {
  error: "Danger",
  info: "Info",
  success: "Success",
};
const AlertTemplate = ({ message, options, style, close }) => {
  return (
    <>
      <div style={{ ...style }}>
        <Alert closable status={mapType[options.type]} onClose={close}>
          {message}
        </Alert>
      </div>
    </>
  );
};

export default AlertTemplate;
