import React from "react";
import { UserContextProvider, UserAuthContext } from "./userAuth";
import { EmployeeContextProvider, EmployeeContext } from "./employeeContext";
import { BreakpointContext } from "./breakpointContext";

import { ApolloProvider } from "@apollo/client";
import client from "../utils/apolloClient";

const ContextProviders = ({ children }) => {
  return (
    <UserContextProvider>
      <ApolloProvider client={client}>
        <EmployeeContextProvider>{children}</EmployeeContextProvider>
      </ApolloProvider>
    </UserContextProvider>
  );
};
export default ContextProviders;
export { UserAuthContext, EmployeeContext, BreakpointContext };
