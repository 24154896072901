import { gql } from "@apollo/client";
import { useLazyQuery } from "@apollo/client";
import Fragments from "./fragments";

const useOrders = () => {
  const QUERY = gql`
    query GetOrdersByRestaurant(
      $sort: String!
      $limit: Int!
      $start: Int!
      $restaurantId: ID!
      $where: JSON
      $locationIds: [ID!]
    ) {
      ordersByRestaurant(
        id: $restaurantId
        locationIds: $locationIds
        limit: $limit
        start: $start
        sort: $sort
        where: $where
      ) {
        totalCount
        values {
          ...OrderFragment
        }
      }
    }
    ${Fragments.order}
  `;
  const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(
    QUERY,
    {
      fetchPolicy: "network-only",
    }
  );

  const onExecuteQuery = (variables) => {
    executeQuery({
      variables,
    });
  };

  return { data, loading, error, onExecuteQuery, refetch };
};

const useOrderByNumber = () => {
  const QUERY = gql`
    query GetOrderByNumber($number: Int!, $restaurantId: ID!) {
      orderByNumber(number: $number, restaurantId: $restaurantId) {
        ...OrderFragment
      }
    }

    ${Fragments.order}
  `;
  const [executeQuery, { data, loading, error }] = useLazyQuery(QUERY);

  const onExecuteQuery = (variables) => {
    executeQuery({
      variables,
    });
  };
  return [data, loading, error, onExecuteQuery];
};

export default useOrders;
export { useOrderByNumber };
