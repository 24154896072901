import "./src/styles/normalize.css";
import "./src/styles/bootstrap-grid.css";
import "./src/styles/bootstrap-utilities.css";

import React from "react";
import Providers from "./src/providers";

export const wrapRootElement = ({ element }) => {
  return <Providers>{element}</Providers>;
};
