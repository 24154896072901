import { gql } from "@apollo/client";
import { useLazyQuery } from "@apollo/client";
import Fragments from "./fragments";

const useUserMe = () => {
  const QUERY = gql`
    query {
      me {
        id
        email
        confirmed
        role {
          type
        }
        restaurants {
          ...RestaurantFragment
        }

        locations {
          id
          name
        }
        displayName
        firstName
        lastName
        phone
        addresses {
          ...AddressFragment
        }
      }
    }
    ${Fragments.address}
    ${Fragments.restaurant}
  `;
  const [executeQuery, { data, loading, error }] = useLazyQuery(QUERY);

  return [data, loading, error, executeQuery];
};

export default useUserMe;
