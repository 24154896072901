import { useMemo } from "react";

const usePriceFormat = ({ location }) => {
  const priceSymbols = useMemo(() => {
    return {
      usd: {
        symbol: "$",
        left: true,
      },
      cad: {
        symbol: "$",
        left: true,
      },
      dkk: {
        symbol: "kr",
        left: false,
      },
    };
  }, []);
  const priceSymbol = useMemo(() => {
    if (!priceSymbols) return "$";
    if (location) return priceSymbols[location.currency].symbol;
  }, [location, priceSymbols]);

  const priceFormat = (price, currency) => {
    if (!price) return price;
    const cf = location
      ? priceSymbols[location.currency]
      : priceSymbols[currency];
    if (cf.left) {
      return `${cf.symbol}${price?.toFixed(2)}`;
    }
    return `${price?.toFixed(2)}${cf.symbol}`;
  };

  return { priceFormat, priceSymbol };
};

export default usePriceFormat;
