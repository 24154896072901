/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react";
import ContextProviders from "../context";
import { ThemeProvider, StyledEngineProvider } from "styled-components";
import themes from "../components/Layouts/themes";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "../components/AlertTemplate";
import { BreakpointContextProvider } from "../context/breakpointContext";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";

const alertOptions = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 5000,
  // you can also just use 'scale'
  transition: transitions.FADE,
  containerStyle: {
    zIndex: 9999,
  },
};

const theme = createTheme();

const queries = {
  sm: "(max-width: 576px)",
  md: "(max-width: 768px)",
  lg: "(max-width: 992px)",
  xl: "(max-width: 1200px)",
  or: "(orientation: portrait)", // we can check orientation also
};

const Providers = ({ children }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={themes("default", "ltr")}>
        <ContextProviders>
          <AlertProvider template={AlertTemplate} {...alertOptions}>
            <BreakpointContextProvider queries={queries}>
              {children}
            </BreakpointContextProvider>
          </AlertProvider>
        </ContextProviders>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default Providers;
